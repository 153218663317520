<template>
  <div>
    <!-- header card -->
    <b-card>
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('apps.transactions.purchase.request.singular.requestor')"
              label-for="requestor"
            >
              <validation-provider
                #default="{ errors }"
                name="requestor"
                rules="required"
              >
                <v-select
                  inputId="requestor"
                  ref="firstFocusSelectInput"
                  v-model="form.requestorContactId"
                  :options="LOV.requestors"
                  :reduce="field => field.id"
                  label="label"
                  :state="errors.length > 0 ? false:null"
                  disabled
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.request.singular.requestor') : null"
                />
                  <!-- <template v-slot:option="option">
                    {{ option.user.user_name }} - {{ option.user.user_email }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.user.user_name }} - {{ option.user.user_email }}
                  </template>
                </v-select> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Transaction No Field -->
          <b-col cols="12" md="3">
            <b-form-group
            :label="$t('apps.transactions.purchase.order.singular.transactionNo')"
            label-for="supplier"
            >
              <b-form-input
                id="invoice-no"
                size="sm"
                v-model="form.code"
                :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.order.singular.transactionNo') : null"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- Date Field -->
          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.date')"
              label-for="date"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <flat-pickr
                  id="date"
                  :config="dateFormat"
                  v-model="form.date"
                  :state="errors.length > 0 ? false:null"
                  disabled
                  class="form-control form-control-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              :label="$t('globalSingular.workUnit')"
              label-for="work-unit">
              <validation-provider
                #default="{ errors }"
                name="work-unit"
                rules="required"
              >
                <v-select
                  id="work-unit"
                  v-model="form.workUnitId"
                  :options="LOV.workUnits"
                  :reduce="field => field.id"
                  label="name"
                  :state="errors.length > 0 ? false:null"
                  disabled
                  class="select-size-sm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Reference Code -->
          <!-- <b-col cols="12" md="3">
            <b-form-group
            :label="$t('globalSingular.referenceCode')"
            label-for="referenceCode"
            >
              <b-form-input
                id="referenceCode"
                size="sm"
                v-model="form.referenceCode"
                :placeholder="!actions.isPreview ? $t('globalSingular.referenceCode') : null"
                disabled
              />
            </b-form-group>
          </b-col> -->

          <!-- Tag Field -->
          <b-col cols="12" md="4">
            <b-form-group
                :label="$t('apps.transactionsCashbank.singular.tags')"
                label-for="tags">
              <v-select
                v-model="form.tags"
                inputId="tags"
                label="name"
                multiple
                disabled
                :options="LOV.tags"
                :reduce="field => field.id"
                :searchable="!actions.isPreview"
                :selectable="option => !actions.isPreview"
                class="select-size-sm"
                :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.singular.tags') : null"
              />
            </b-form-group>
          </b-col>

          <!-- <b-row> -->
          <!-- PIC Name Field -->
          <b-col cols="12" md="4">
            <b-form-group
            :label="$t('apps.transactions.purchase.request.singular.PICName')"
            label-for="picName"
            >
              <b-form-input
                id="picName"
                size="sm"
                v-model="form.picName"
                :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.request.singular.PICName') : null"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- PIC Address Field -->
          <b-col cols="12" md="4">
            <b-form-group
            :label="$t('apps.transactions.purchase.request.singular.PICAddress')"
            label-for="picAddress"
            >
              <b-form-input
                id="picAddress"
                size="sm"
                v-model="form.picAddress"
                :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.request.singular.PICAddress') : null"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- PIC Phone Field -->
          <b-col cols="12" md="4">
            <b-form-group
            :label="$t('apps.transactions.purchase.request.singular.PICPhone')"
            label-for="picPhone"
            >
              <b-form-input
                id="picPhone"
                size="sm"
                v-model="form.picPhone"
                :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.request.singular.PICPhone') : null"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- CATEGORY -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('globalSingular.category')"
              label-for="category"
            >
              <validation-provider
                #default="{ errors }"
                name="category"
                rules="required"
              >
                <v-select
                  inputId="category"
                  ref="firstFocusSelectInput"
                  v-model="form.categoryId"
                  :options="LOV.categories"
                  :reduce="field => field.id"
                  label="name"
                  @option:selected="categoryChange"
                  :state="errors.length > 0 ? false:null"
                  disabled
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('globalSingular.category') : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- SUB CATEGORY -->
          <b-col cols="12" md="4">
            <b-form-group
              :label="$t('globalSingular.subCategory')"
              label-for="requestor"
            >
              <validation-provider
                #default="{ errors }"
                name="requestor"
                rules="required"
              >
                <v-select
                  inputId="requestor"
                  ref="firstFocusSelectInput"
                  v-model="form.subCategoryId"
                  :options="LOV.subCategories"
                  :reduce="field => field.id"
                  label="name"
                  @option:selected="subCategoryChange"
                  :state="errors.length > 0 ? false:null"
                  disabled
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('globalSingular.subCategory') : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- </b-row> -->
          <b-col cols="12" md="12">
            <b-form-group
              :label="$t('apps.transactionsCashbank.singular.notes')"
              label-for="notes">
              <b-form-textarea
                id="notes"
                size="sm"
                v-model="form.remark"
                rows="1"
                :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Assign Supplier -->
          <b-col cols="12" md="12">
            <b-form-group
              :label="$t('globalSingular.supplier')"
              label-for="supplier"
            >
              <validation-provider
                #default="{ errors }"
                name="supplier"
                rules="required"
              >
                <v-select
                  inputId="supplier"
                  ref="firstFocusSelectInput"
                  v-model="form.vendor"
                  :options="LOV.suppliers"
                  :reduce="field => field.id"
                  label="label"
                  multiple
                  :state="errors.length > 0 ? false:null"
                  :disabled="actions.isPreview"
                  class="select-size-sm"
                  :placeholder="!actions.isPreview ? $t('globalSingular.supplier') : null"
                />
                  <!-- <template v-slot:option="option">
                    {{ option.user.user_name }} - {{ option.user.user_email }}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.user.user_name }} - {{ option.user.user_email }}
                  </template>
                </v-select> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
    </b-card>

    <b-card>

      <b-row >
        <b-col
          cols="12"
        >
          <validation-observer ref="formRef">
            <b-form @submit.prevent>

              <b-table
                id="refFormTable"
                ref="refFormTable"
                no-provider-paging
                no-provider-filtering
                :fields="tableColumns"
                :items="form.details"
                primary-key="id"
                show-empty
                responsive
                class="table-responsive"
              >
                <!-- Custom Header Column -->
                <template #head(actions)>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="mx-auto"
                  />
                </template>
                <!-- Column: Product -->
                <template #cell(product)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="product"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        v-model="form.details[index].itemId"
                        :options="LOV.products"
                        :reduce="field => field.id"
                        label="name"
                        :state="errors.length > 0 ? false:null"
                        disabled
                        :clearable="false"
                        @option:selected="setAmountAndUom(index)"
                        class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <b-form-input
                        v-model="form.details[index].remark"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                        disabled
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Unit -->
                <template #cell(unit)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="unit"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        v-model="form.details[index].uomId"
                        :options="LOV.uoms"
                        :reduce="field => field.id"
                        label="uom"
                        :state="errors.length > 0 ? false:null"
                        disabled
                        :clearable="false"
                        class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Qty -->
                <template #cell(qty)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="quantity"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <b-form-input
                        class="text-right"
                        type="number"
                        min="1"
                        v-model.number="form.details[index].quantity"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                        disabled
                        @blur="updateItemAmount(index)"
                        size="sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
                <!-- Column: Price -->
                <!-- <template #cell(price)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="price"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <cleave
                        :key="index"
                        v-model.number="form.details[index].amount"
                        :state="errors.length > 0 ? false:null"
                        class="form-control text-right form-control-sm"
                        :options="numeric"
                        disabled
                        :placeholder="actions.isPreview ? null : '100,000'"
                        @blur="updateItemAmount(index)"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template> -->
                <!-- Column: Discount Amount -->
                <!-- <template #cell(discount)="{ index }">
                  <b-form-group>
                    <b-form-input
                      class="text-right"
                      type="number"
                      min="0"
                      v-model.number="form.details[index].discPercent"
                      :placeholder="!actions.isPreview ? $t('apps.transactionsCashbank.placeholder.description') : null"
                      disabled
                      @blur="updateItemAmount(index)"
                      size="sm"
                    />
                  </b-form-group>
                </template> -->
                <!-- Column: Tax Amount -->
                <!-- <template #cell(tax)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="tax"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        v-model="form.details[index].taxId"
                        :options="LOV.taxes"
                        :reduce="field => field.id"
                        label="name"
                        :state="errors.length > 0 ? false:null"
                        disabled
                        :clearable="false"
                        @option:selected="updateItemAmount(index)"
                        class="select-size-sm"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template> -->
                <!-- Column: Total Amount -->
                <!-- <template #cell(total)="{ index }">
                  <span class="font-weight-bolder d-block text-right text-nowrap align-top form-control border-0 pr-0">
                    {{ formatCurrency(form.details[index].baseAmount) }}
                  </span>
                </template> -->
                <!-- Column: Actions -->
                <template #cell(actions)="{ index }">
                  <b-button
                    variant="flat-danger"
                    class="btn-icon"
                    :disabled="form.details.length <= 2"
                    @click="removeRow(index)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </template>
                <!-- Costum Footer -->
                <!-- <template v-slot:custom-foot="{}">
                  <b-tr>
                    <b-td colspan="4" class="p-0">
                      <b-button
                        v-if="!actions.isPreview"
                        variant="outline-primary"
                        block
                        @click="addRow"
                      >
                        <feather-icon
                          icon="PlusIcon"
                        />
                        {{ $t('globalActions.addMore') }}
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2">&nbsp;</b-td>
                    <b-td class="pl-5 py-1">
                      <div class="h5 mb-0 font-weight-bolder">{{ $t('globalSingular.subTotal') }}</div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="h5 mb-0 font-weight-bolder">{{ formatCurrency(sumOfAmount) }}</div>
                    </b-td>
                    <b-td>&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="border-0">&nbsp;</b-td>
                    <b-td class="border-0 pb-1 pl-5">
                      <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.discount') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline">
                      <div class="h6 mb-0 font-weight-bolder text-danger"> -{{ formatCurrency( form.sumOfDiscAmount + form.sumOfDiscGlobalAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="border-0">&nbsp;</b-td>
                    <b-td class="border-0 pb-1 pl-5">
                      <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.taxBase') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline">
                      <div class="h6 mb-0 font-weight-bolder">{{ formatCurrency( form.sumOfTaxBaseAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="border-0">&nbsp;</b-td>
                    <b-td class="border-0 pb-1 pl-5">
                      <div class="h6 mb-0 font-weight-bolder">{{ $t('globalSingular.tax') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline">
                      <div class="h6 mb-0 font-weight-bolder">{{ formatCurrency( form.sumOfTaxAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="border-0">&nbsp;</b-td>
                    <b-td colspan="2" class="border-0 pl-5 py-0">
                      <hr>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2" class="border-0">
                      &nbsp;
                    </b-td>
                    <b-td class="border-0 align-baseline pl-5">
                      <div class="h4 mb-0 font-weight-bolder">{{ $t('globalSingular.total') }}</div>
                    </b-td>
                    <b-td class="border-0 text-right align-baseline pl-4">
                      <div class="h4 mb-0 font-weight-bolder">{{ formatCurrency( form.sumOfBillAmount) }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                </template> -->
              </b-table>

              <b-row class="my-1">
                <!-- Description Field -->
                <b-col cols="12" md="12">

                </b-col>
              </b-row>

              <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                  :to="{ name: 'apps-transactions-purchase-request-assign-vendor-list' }"
                >
                  <feather-icon
                    icon="ArrowLeftIcon"
                  />
                  {{ $t('globalActions.backToList') }}
                </b-button>

                <b-button
                  v-if="actions.isPreview"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-danger"
                  @click="handleDelete"
                >
                  <feather-icon
                    icon="Trash2Icon"
                  />
                  {{ $t('globalActions.delete') }}
                </b-button>

                <!-- <b-button
                  v-if="actions.isPreview"
                  class=" mb-sm-1 mb-md-0"
                  variant="outline-primary"
                  :to="{ name: 'apps-transactions-purchase-invoice-prints', params: { id: paramsId }}"
                >
                  <feather-icon
                    icon="EyeIcon"
                  />
                  {{ $t('globalActions.prints') }}
                </b-button> -->

                <!-- <b-button
                  v-if="actions.isPreview && !isInvoiced"
                  class=" mb-sm-1 mb-md-0"
                  variant="success"
                  @click="handleDirectInvoice"
                >
                  <feather-icon
                    icon="CheckIcon"
                  />
                  {{ $t('globalActions.approveToInvoice') }}
                </b-button> -->


                <b-button
                  v-if="!actions.isPreview"
                  variant="primary"
                  type="submit"
                  @click="handleSubmit"
                >
                  <feather-icon
                    icon="SaveIcon"
                  />
                  {{ $t('globalActions.submit') }}
                </b-button>

                <b-button
                  v-else
                  variant="outline-primary"
                  @click="changeToEdit"
                >
                  <feather-icon
                    icon="EditIcon"
                  />
                  {{ $t('globalActions.assignVendor') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  // BTr,
  // BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import { ref, onMounted } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { formatCurrency } from '@/utils/formatter'

import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'

const DETAIL_INVOICE = {
  rowNumber: 1,
  itemId: 'Product',
  remark: '',
  uomId: 'Unit',
  quantity: 1,
  amount: 0,
  discPercent: 0,
  discAmount: 0,
  baseAmount: 0,
  discGlobalPercent: 0,
  discGlobalAmount: 0,
  taxBaseAmount: 0,
  taxId: null,
  taxRate: 10,
  taxAmount: 0,
  billAmount: 0
}

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    // BTr,
    // BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr
    // Cleave
  },
  setup () {
    const {
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/purchase/request', localeContextPath: 'apps.transactions.purchase.invoice.singular.invoice', redirectPathName: 'apps-transactions-purchase-request-assign-vendor' })

    const { $t } = useLocalization()

    const LOV = ref({
      workUnits: [],
      products: [],
      requestors: [],
      suppliers: [],
      categories: [],
      subCategories: [],
      uoms: [],
      tags: [],
      taxes: []
    })

    const getSuppliers = async () => {
      LOV.value.suppliers = await get({ url: 'value/contact/supplier' })
    }

    const getCategories = async () => {
      LOV.value.categories = await get({ url: 'value/product-category' })
    }

    const getSubCategories = async categoryId => {
      LOV.value.subCategories = await get({ url: `value/product-sub-category?category_id=${categoryId}` })
    }

    const getWorkUnits = async () => {
      LOV.value.workUnits = await get({ url: 'value/work-unit' })
    }

    const getRequestor = async () => {
      LOV.value.requestors = await get({ url: 'value/contact/employee' })
    }

    const getProducts = async subCategoryId => {
      LOV.value.products = await get({ url: `value/product?is_buying=true&sub_category_id=${subCategoryId}`})
    }

    const getUoms = async () => {
      LOV.value.uoms = await get({ url: 'value/uom'})
    }

    const getTaxes = async () => {
      LOV.value.taxes = await get({ url: 'value/tax'})
    }

    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const tableColumns = ref([])

    const isInvoiced = ref(true)

    const form = ref({
      // contactId: '',
      code: '',
      date: '',
      vendor: [],
      categoryId: '',
      subCategoryId: '',
      requestorContactId: '',
      picName: '',
      picAddress: '',
      picPhone: '',
      remark: '',
      // referenceCode: '',
      // referenceDate: '',
      workUnitId: '',
      tags: [],
      sumOfQuantity: 0,
      sumOfAmount: 0,
      sumOfDiscAmount: 0,
      sumOfBaseAmount: 0,
      sumOfDiscGlobalAmount: 0,
      sumOfTaxBaseAmount: 0,
      sumOfTaxAmount: 0,
      sumOfBillAmount: 0,
      outstandingAmount: 0,
      details: [
        // doing 'tricked' placeholder for vue-select accountId
        { ...DETAIL_INVOICE }
      ]
    })

    const companyActive = storestate.state.user.currentCompany
    const dateFormat = ref({
      dateFormat: 'd/m/Y',
      disable: [
        function (date) {
          return (date.getTime() < (new Date(companyActive.closing_year,  companyActive.closing_month, 1).getTime()))
        }
      ]
    })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    const sumOfAmount = ref(0)

    const sumItemAmount = () => {
      sumOfAmount.value = form.value.details.reduce((accumulator, {quantity, amount}) => accumulator + (amount * quantity), 0)
      form.value.sumOfQuantity = form.value.details.reduce((accumulator, {quantity}) => accumulator + quantity, 0)
      form.value.sumOfAmount = form.value.details.reduce((accumulator, {amount}) => accumulator + amount, 0)
      form.value.sumOfDiscAmount = form.value.details.reduce((accumulator, {discAmount}) => accumulator + discAmount, 0)
      form.value.sumOfBaseAmount = form.value.details.reduce((accumulator, {baseAmount}) => accumulator + baseAmount, 0)
      form.value.sumOfDiscGlobalAmount = form.value.details.reduce((accumulator, {discGlobalAmount}) => accumulator + discGlobalAmount, 0)
      form.value.sumOfTaxBaseAmount = form.value.details.reduce((accumulator, {taxBaseAmount}) => accumulator + taxBaseAmount, 0)
      form.value.sumOfTaxAmount = form.value.details.reduce((accumulator, {taxAmount}) => accumulator + taxAmount, 0)
      form.value.sumOfBillAmount = form.value.details.reduce((accumulator, {billAmount}) => accumulator + billAmount, 0)
      form.value.outstandingAmount = form.value.sumOfBillAmount
    }


    const updateItemAmount = index => {
      const baseAmountBeforeDisc = form.value.details[index].quantity * form.value.details[index].amount

      if (!form.value.details[index].taxRate) {
        const tax = LOV.value.taxes.find(field => field.id === form.value.details[index].taxId)
        form.value.details[index].taxRate = tax.rate
      }

      form.value.details[index].discAmount = (baseAmountBeforeDisc * form.value.details[index].discPercent) / 100
      form.value.details[index].baseAmount = baseAmountBeforeDisc - form.value.details[index].discAmount
      form.value.details[index].taxBaseAmount = form.value.details[index].baseAmount - form.value.details[index].discGlobalAmount
      form.value.details[index].taxAmount = (form.value.details[index].taxBaseAmount * form.value.details[index].taxRate) / 100
      form.value.details[index].billAmount = form.value.details[index].taxBaseAmount + form.value.details[index].taxAmount
      sumItemAmount()
    }

    // SET AMOUNT AND UNIT BASED ON SELECTED PRODUCT
    const setAmountAndUom = index => {
      // @TODO : SET AMOUNT AND UNIT BASED ON SELECTED PRODUCT
      const { buying_amount, uom: {id} } = LOV.value.products.find(field => field.id === form.value.details[index].itemId)
      form.value.details[index].uomId = id
      form.value.details[index].amount = parseInt(buying_amount)
      updateItemAmount(index)
    }

    // Add row details
    const addRow = () => {
      const detailInvoice = {...DETAIL_INVOICE}
      detailInvoice.rowNumber = form.value.details.length + 1
      form.value.details.push({
        rowNumber: form.value.details.length + 1,
        ...detailInvoice
      })
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await show()
      isInvoiced.value = data.is_invoiced
      form.value.contactId = data.contact_id
      form.value.code = data.code
      form.value.date = data.date
      form.value.vendor = data.vendor
      form.value.categoryId = data.category_id
      form.value.subCategoryId = data.sub_category_id
      form.value.requestorContactId = data.requestor_contact_id
      form.value.picName = data.pic_name
      form.value.picAddress = data.pic_address
      form.value.picPhone = data.pic_phone
      form.value.workUnitId = data.work_unit_id
      form.value.remark = data.remark
      form.value.tags = data.tags ? data.tags.map(tag => parseInt(tag)) : []
      form.value.details = data.details.map(field => {
        const detail = {}
        detail.rowNumber = field.row_number
        detail.itemId = field.item_id
        detail.remark = field.remark
        detail.uomId = field.uom_id
        detail.quantity = parseFloat(field.quantity)
        detail.amount = parseFloat(field.amount)
        detail.discPercent = (parseFloat(field.disc_amount) /  parseFloat(field.amount)) * 100
        detail.discAmount = parseFloat(field.disc_amount)
        detail.baseAmount = parseFloat(field.base_amount)
        detail.discGlobalPercent = parseFloat(field.disc_global_percent)
        detail.discGlobalAmount = parseFloat(field.disc_global_amount)
        detail.taxBaseAmount = parseFloat(field.tax_base_amount)
        detail.taxId = parseFloat(field.tax_id)
        detail.taxRate = (parseFloat(field.tax_amount) /  parseFloat(field.dpp_amount)) * 100
        detail.taxAmount = parseFloat(field.tax_amount)
        detail.billAmount = parseFloat(field.bill_amount)
        return detail
      })
      getSubCategories(data.category_id)
      getProducts(data.sub_category_id)
      sumItemAmount()
    }

    // Remove row details
    const removeRow =  (index) => {
      if (form.value.details.length <= 1) return false
      form.value.details.splice(index, 1)
    }

    const categoryChange = option => getSubCategories(option.id)
    const subCategoryChange = option => getProducts(option.id)

    onMounted(() => {
      getSuppliers()
      getCategories()
      getWorkUnits()
      getRequestor()
      // getProducts()
      getUoms()
      getTags()
      getTaxes()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      tableColumns.value = [
        { key: 'product',  label: $t('apps.transactions.purchase.singular.product'), thClass: '', tdClass: 'py-1 align-top pl-0' },
        { key: 'unit', label: $t('apps.transactions.purchase.singular.unit'), thClass: 'text-right ', tdClass: 'py-1 align-top text-right'},
        { key: 'qty', label: $t('apps.transactions.purchase.singular.qty'), thClass: 'text-right ', tdClass: 'py-1 align-top text-right'},
        // { key: 'price', label: $t('apps.transactions.purchase.singular.price'), thClass: 'text-right width-20-per', tdClass: 'py-1 align-top text-right'},
        // { key: 'discount', label: $t('apps.transactions.purchase.singular.discount'), thClass: 'text-right ', tdClass: 'py-1 align-top text-right'},
        // { key: 'tax', label: $t('apps.transactions.purchase.singular.tax'), thClass: 'text-right ', tdClass: 'py-1 align-top text-right'},
        // { key: 'total', label: $t('apps.transactions.purchase.singular.total'), thClass: 'text-right ', tdClass: 'py-1 align-top text-right'},
        { key: 'actions', thClass: 'text-right ', tdClass: 'py-1 align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    return {
      categoryChange,
      subCategoryChange,
      formatCurrency,
      pushTags,
      required,
      LOV,
      tableColumns,
      form,
      addRow,
      removeRow,
      dateFormat,
      sumOfAmount,
      updateItemAmount,
      setAmountAndUom,
      numeric,
      paramsId,
      isInvoiced,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    }
  },
  methods: {
    handleDelete () {
      this.destroy(this.$swal)
    },
    async handleSubmit () {
      // this.form.isInvoiced = true
      this.store({
        $swal: this.$swal,
        data: this.form
      })
    }
    // async handleDirectInvoice () {
    //   this.form.isInvoiced = true
    //   this.store({
    //     $swal: this.$swal,
    //     data: this.form
    //   })
    // }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
